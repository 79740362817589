<template>
    <div class="company">
       <div class="company-box-one" :style="{height:screenHeight + 'px',marginBottom:30+'px'}">
            <div class="company-text-one">
                <div class="company-title-one">
                    <span>{{date.Maintitle}}</span>
                </div>
                <div class="company-subtitle-one">
                    <span>{{date.Subtitle}}</span>
                </div>
                <div class="company-line-one"></div>
                <div class="company-content-one">
                    <div v-html="date.PContent"></div>
                </div>
            </div>
            <div class="company-banner-one">
                <el-carousel  :interval="5000" height="450px" trigger="click">
                    <el-carousel-item v-for="(item,index) in date.bannerList" :key="index">
                        <img :src="item.PImage" @click="backPPT" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
       </div>
       <div class="company-box" :style="{height:screenHeight + 'px'}">
            <div class="company-text">
                <div class="company-title">
                    <span>{{formData.Maintitle}}</span><span @click="backPPT" class="PPT">详情</span>
                </div>
                <div class="company-subtitle">
                    <span>{{formData.Subtitle}}</span>
                </div>
                <div class="company-line"></div>
                <div class="company-content" @click="backPPT">
                    <div v-html="formData.PContent"></div>
                </div>
            </div>
            <div class="company-banner">
                <el-carousel  :interval="5000" :height="Height" trigger="click">
                    <el-carousel-item v-for="(item,index) in formData.bannerList" :key="index">
                        <img :src="item.PImage" @click="backPPT" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
       </div>
    </div>
</template>
<script>
    import { ShowPortalInfo } from "../api/home.js";
    export default {
        name: "company",
        data(){
            return{
                screenHeight: document.documentElement.clientHeight-120,
                Height: ((document.documentElement.clientHeight-120)*0.8)+'px',
                date:{},
                formData:{},
            }
        },
        mounted(){
            this.Info("公司简介")
            this.Info("品牌简介")
        },
        methods:{
            backPPT(){
                window.open('https://view.officeapps.live.com/op/view.aspx?src='+encodeURIComponent('https://pd-palmes.palmes.shop/%E5%B8%95%E9%A9%AC%E4%BB%954.7.pptx'))
            },
            Info(date){
                let params=`pType=${date}`
                ShowPortalInfo(params).then(res=>{
                    if(date=='公司简介'){
                        this.date = res.Data[0]
                        let arr = this.date.PImage.split(',')
                        this.date.bannerList = arr.map(item=>{
                            return {PImage:item}
                        })
                    }else{
                        this.formData = res.Data[0]
                        let arr = this.formData.PImage.split(',')
                        this.formData.bannerList = arr.map(item=>{
                            return {PImage:item}
                        })
                    }
                })
            },
        },
    }
</script>
<style scoped lang="scss">
    .PPT{
        text-decoration:underline;
        cursor: pointer;
        color: #0505AE;
        font-size: 16px;
    }
    .company {
        overflow: hidden;
        width: 100%;
        &-box{
            background-image: url('../assets/img/meigui.png');
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: 50%;
            width: 1103px;
            margin: 0 auto;
            height: 100%;
            display: flex;
        }
        &-text{
            width: 50%;
            height: 100%;
        }
        &-banner{
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            .el-carousel{
                margin: 0 auto;
                width: 345px;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                }
                /deep/.el-carousel__indicators--horizontal{
                    bottom: 10px;
                    z-index: 89;
                }
                /deep/.el-carousel__button{
                    width: 8px;
                    height: 8px;
                    background: #E0E0E0;
                    border-radius: 50%;
                    opacity: 1;
                }
                /deep/.el-carousel__indicator--horizontal{
                    padding: 0 !important;
                    margin: 0 6px;
                }
                /deep/.el-carousel__indicator--horizontal.is-active{
                    width: 8px;
                    height: 8px;
                    background: #0505AF;
                    border-radius: 4px;
                }
                /deep/.is-active .el-carousel__button{
                    width: 8px;
                    height: 8px;
                    background: #0505AF;
                    border-radius: 4px;
                }
            }
        }
        &-title{
            font-size: 45px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #2A2A3E;
            margin-top: 20%;
        }
        &-subtitle{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F4F6C;
            margin-top: 20px;
        }
        &-line{
            margin-top: 50px;
            width: 80px;
            height: 5px;
            background: #0505AE;
            border-radius: 5px;
        }
        &-content{
            margin-top: 50px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000;
            letter-spacing: 2px;
            font-family: '微软雅黑';
            text-indent:2em;
        }
        &-box-one{
            background-image: url('../assets/img/meigui.png');
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: 50%;
            width: 1103px;
            margin: 0 auto;
            height: 100%;
            display: flex;
        }
        &-text-one{
            width: 50%;
            height: 100%;
        }
        &-banner-one{
            width: 600px;
            height: 100%;
            display: flex;
            align-items: center;
            .el-carousel{
                margin: 30% 0 0 60px;
                width: 540px;
                position: relative;
                img{
                    width: 100%;
                    height: 450px;
                }
                /deep/.el-carousel__indicators--horizontal{
                    bottom: 10px;
                    z-index: 89;
                }
                /deep/.el-carousel__button{
                    width: 8px;
                    height: 8px;
                    background: #E0E0E0;
                    border-radius: 50%;
                    opacity: 1;
                }
                /deep/.el-carousel__indicator--horizontal{
                    padding: 0 !important;
                    margin: 0 6px;
                }
                /deep/.el-carousel__indicator--horizontal.is-active{
                    width: 8px;
                    height: 8px;
                    background: #0505AF;
                    border-radius: 4px;
                }
                /deep/.is-active .el-carousel__button{
                    width: 8px;
                    height: 8px;
                    background: #0505AF;
                    border-radius: 4px;
                }
            }
        }
        &-title-one{
            font-size: 45px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #2A2A3E;
            margin-top: 20%;
        }
        &-subtitle-one{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F4F6C;
            margin-top: 20px;
        }
        &-line-one{
            margin-top: 50px;
            width: 80px;
            height: 5px;
            background: #0505AE;
            border-radius: 5px;
        }
        &-content-one{
            margin-top: 50px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000;
            letter-spacing: 2px;
            font-family: '微软雅黑';
            text-indent:2em;
        }
    }
    .company::-webkit-scrollbar { width: 0 !important }
    .company { -ms-overflow-style: none; }
    .company { overflow: -moz-scrollbars-none; }
</style>